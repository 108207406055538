export const de_de = {
    "_name": "Deutch",
    "_direction": "ltr",
    "_welcome": "Guten Tag %message",
    "_national_days": "10-03",
    "common": {
        "save": "Speichern",
        "cancel": "Stornieren",
        "delete": "Löschen",
        "ok": "OK",
        "actions": {
            "save_failed": "Änderungen konnten nicht gespeichert werden"
        }
    },
    "login": {
        "imid_button": "Melden Sie sich mit IMid an",
        "license": "Lizenz",
        "actions": {
            "failed": "Einloggen fehlgeschlagen",
            "relogin_required": "Erneute Anmeldung erforderlich"
        }
    },
    "main": {
        "how_can_i_help_you": "Wie kann ich dir helfen?",
        "image_answer": "Grafische Antwort",
        "actions": {
            "query_failed": "Entschuldigung, ich konnte die Antwort nicht finden."
        }
    },
    "menu": {
        "home": "Startbildschirm",
        "settings": "Einstellungen"
    },
    "settings": {
        "tabs": {
            "account": "Konto",
            "devices": "Geräte",
            "app": "App",
            "license": "Lizenz"
        },
        "account": {
            "full_name": "Vorname und Nachname",
            "email_address": "E-Mail-Adresse",
            "logout": "Ausloggen",
            "andra": {
                "account": "Konto von Andra",
                "device": "Gerät",
                "device_name": "Gerätename"
            },
            "actions": {
                "logout": "Sie haben sich erfolgreich von der Anwendung abgemeldet"
            }
        },
        "devices": {
            "connected_devices": "Verbundene Geräte",
            "device_name": "Name",
            "device_id": "Ausweis",
            "options": "Optionen",
            "change_name": "Umbenennen",
            "actions": {
                "device_removed": "Das Gerät wurde gelöscht",
                "device_remove_failed": "Das Gerät konnte nicht entfernt werden",
                "device_saved": "Änderungen gespeichert",
            }
        },
        "app": {
            "settings": "Anwendungseinstellungen",
            "enable_voice": "Verwenden Sie Sprachantworten",
            "disable_voice": "Schalten Sie Sprachantworten aus"
        }
    },
    "nyumba": {
        "work_mode": {
            "unknown": "UNBEKANNT",
            "home": "Zu Hause",
            "away": "Draußen",
            "manual": "Manual",
            "silence": "Ruhig"
        },
        "alarm": {
            "enter_code": "Geben Sie den Alarmcode ein",
            "state": {
                "active": "Aktiv",
                "waked_up": "Aufgeregt",
                "run": "Gestartet",
                "sleeping": "Es wird geschlafen...",
                "disabling": "Deaktivierung läuft...",
                "inactive": "Inaktiv"
            },
            "actions": {
                "activate": "Ein Alarmaktivierungsbefehl wurde gesendet",
                "activation_failed": "Der Alarm konnte nicht aktiviert werden",
                "send_command": "Es wurde ein Befehl an den Alarm gesendet",
                "send_command_failed": "Der Befehl konnte nicht gesendet werden"
            }
        },
        "actions": {
            "send_command": "Der Befehl wurde gesendet",
            "data_failed": "Daten konnten nicht geladen werden",
            "send_failed": "Die Datenübertragung ist fehlgeschlagen"
        }
    }
}
