export const en = {
    "_name": "English",
    "_direction": "ltr",
    "_alphabet": "latin",
    "_welcome": "Hello %message",
    "_welcome_name_process_function": "getEn",
    "_national_days": "",
    "_months": [
        {id: 1, name: 'January'},
        {id: 2, name: 'February'},
        {id: 3, name: 'March'},
        {id: 4, name: 'April'},
        {id: 5, name: 'May'},
        {id: 6, name: 'June'},
        {id: 7, name: 'July'},
        {id: 8, name: 'August'},
        {id: 9, name: 'September'},
        {id: 10, name: 'October'},
        {id: 11, name: 'November'},
        {id: 12, name: 'December'}
    ],
    "_date_format": '%m/%d/%Y',
    "common": {
        "save": "Save",
        "cancel": "Cancel",
        "delete": "Delete",
        "ok": "OK",
        "content": "Content",
        "close": "Close",
        "name": "Name",
        "url": "URL address",
        "add": "Add",
        "yes": "Yes",
        "no": "No",
        "actions": {
            "save_failed": "The changes cannot be saved",
            "failed_to_load_data": "Failed to load data",
            "are_you_sure_to_delete_this_item": "Are you sure to delete this item?"
        }
    },
    "login": {
        "imid_button": "Login with IMid",
        "license": "License",
        "actions": {
            "failed": "Login failed",
            "relogin_required": "Login required",
            "not_allowed": "You don't have access to this version of an application."
        }
    },
    "main": {
        "how_can_i_help_you": "How can I help you?",
        "image_answer": "Graphical response",
        "actions": {
            "query_failed": "Unfortunately, I cannot find the answer."
        }
    },
    "menu": {
        "home": "Home",
        "note": "Notes",
        "calendars": "Calendars",
        "finance": "Finance",
        "integrations": "Integrations",
        "settings": "Settings",
        "notifications": {
            "empty": "No notifications"
        },
        "imid": {
            "authorization": "IMid Authorization",
            "message": "IMid login authenticated"
        }
    },
    "settings": {
        "tabs": {
            "account": "Account",
            "devices": "Devices",
            "app": "App",
            "license": "License"
        },
        "account": {
            "full_name": "Full name",
            "email_address": "E-mail address",
            "logout": "Logout",
            "andra": {
                "account": "Andra Account",
                "device": "Device",
                "device_name": "Device's name"
            },
            "actions": {
                "logout": "Successful logout"
            }
        },
        "devices": {
            "connected_devices": "Connected devices",
            "device_name": "Device's name",
            "device_id": "Id",
            "options": "Options",
            "change_name": "Change name",
            "actions": {
                "device_removed": "Device was removed",
                "device_remove_failed": "Device remove failed",
                "device_saved": "Saved changes"
            }
        },
        "app": {
            "settings": "App settings",
            "enable_voice": "Use voice",
            "disable_voice": "Disable voice",
            "system_info": {
                "title": "System information",
                "version": "Version"
            }
        },
        "language": {
            "actions": {
                "success": "Language settings saved",
                "failed": "Saving language settings failed",
            }
        }
    },
    "nyumba": {
        "work_mode": {
            "unknown": "UNKNOWN",
            "home": "At home",
            "away": "Away",
            "manual": "Manual",
            "silence": "Silence"
        },
        "headers": {
            "scenes": "Scenes",
            "devices": "Devices"
        },
        "alarm": {
            "enter_code": "Enter the alarm code",
            "state": {
                "active": "Active",
                "waked_up": "Waked up",
                "run": "Run",
                "sleeping": "Sleeping...",
                "disabling": "Deactivating...",
                "inactive": "Inactive"
            },
            "actions": {
                "activate": "The alarm activate command was sent",
                "activation_failed": "System cannot activate the alarm",
                "send_command": "Command was sent to alarm",
                "send_command_failed": "Command sending failed"
            }
        },
        "devices": {
            "unknown_devices": "Unassigned devices"
        },
        "actions": {
            "send_command": "Command was sent",
            "data_failed": "Data cannot be loaded",
            "send_failed": "Data cannot be send"
        }
    },
    "finance": {
        "balance": "Balance",
        "budget": "Budget utilization",
        "default_categories": {
            "flat": "Flat",
            "bills": "Bills",
            "food": "Food",
            "party": "Entertainment",
            "other": "Other"
        },
        "actions": {
            "saved": "Changes saved",
            "failed": "Failed to save changes",
            "nan": "Invalid value"
        }
    },
    "calendars": {
        "data_loading_error": "Failed to load data",
        "empty_day": "No events",
        "subscribed_calendars": {
            "title": "Subscribed calendars",
            "add_new": "Add new calendar",
            "added": "Subscribed new calendar",
            "removed": {
                "success": "Calendar was removed",
                "error": "Failed to remove calendar"
            }
        },
        "event": {
            "date": "Date",
            "time": "Time",
            "place": "Place",
            "participants": "Participants",
            "details": "Details"
        }
    },
    "integrations": {
        "webhooks": {
            "title": "Webhooks",
            "name": "Webhook name",
            "actions": "Actions",
            "new_action": "New action"
        }
    }
}
