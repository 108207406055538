export const ar = {
    "_name": "\u0639\u0631\u0628\u064a",
    "_direction": "rtl",
    "_alphabet": "arabic",
    "_welcome": "\u0645\u0631\u062d\u0628\u0627 %message",
    "_welcome_name_process_function": "getEn",
    "_national_days": "05-01,05-02,05-03,11-11,08-15,2023-10-15",
    "_months": [
        {id: 1, name: 'January'},
        {id: 2, name: 'February'},
        {id: 3, name: 'March'},
        {id: 4, name: 'April'},
        {id: 5, name: 'May'},
        {id: 6, name: 'June'},
        {id: 7, name: 'July'},
        {id: 8, name: 'August'},
        {id: 9, name: 'September'},
        {id: 10, name: 'October'},
        {id: 11, name: 'November'},
        {id: 12, name: 'December'}
    ],
    "common": {
        "save": "\u064a\u062d\u0641\u0638",
        "cancel": "\u064a\u0644\u063a\u064a",
        "delete": "\u064a\u0645\u0633\u062d",
        "ok": "\u062a\u0642\u0631\u064a\u0628\u0627",
        "actions": {"save_failed": "\u0641\u0634\u0644 \u0641\u064a \u062d\u0641\u0638 \u0627\u0644\u062a\u063a\u064a\u064a\u0631\u0627\u062a"}
    },
    "login": {
        "imid_button": "\u062a\u0633\u062c\u064a\u0644 \u0627\u0644\u062f\u062e\u0648\u0644 \u0645\u0639 IMid",
        "license": "\u0631\u062e\u0635\u0629",
        "actions": {
            "failed": "\u0641\u0634\u0644 \u0641\u064a \u062a\u0633\u062c\u064a\u0644 \u0627\u0644\u062f\u062e\u0648\u0644",
            "relogin_required": "\u0645\u0637\u0644\u0648\u0628 \u0625\u0639\u0627\u062f\u0629 \u062a\u0633\u062c\u064a\u0644 \u0627\u0644\u062f\u062e\u0648\u0644"
        }
    },
    "main": {
        "how_can_i_help_you": "\u0643\u064a\u0641 \u064a\u0645\u0643\u0646\u0646\u064a \u0645\u0633\u0627\u0639\u062f\u0643\u061f",
        "image_answer": "\u0627\u0644\u0627\u0633\u062a\u062c\u0627\u0628\u0629 \u0627\u0644\u0631\u0633\u0648\u0645\u064a\u0629",
        "actions": {"query_failed": "\u0622\u0633\u0641\u060c \u0644\u0645 \u0623\u062a\u0645\u0643\u0646 \u0645\u0646 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0627\u0644\u062c\u0648\u0627\u0628."}
    },
    "menu": {
        "home": "\u0627\u0644\u0634\u0627\u0634\u0629 \u0627\u0644\u0631\u0626\u064a\u0633\u064a\u0629",
        "settings": "\u0625\u0639\u062f\u0627\u062f\u0627\u062a"
    },
    "settings": {
        "tabs": {
            "account": "\u062d\u0633\u0627\u0628",
            "devices": "\u0627\u0644\u0623\u062c\u0647\u0632\u0629",
            "app": "\u0628\u0631\u0646\u0627\u0645\u062c",
            "license": "\u0631\u062e\u0635\u0629"
        },
        "account": {
            "full_name": "\u0627\u0644\u0627\u0633\u0645 \u0627\u0644\u0623\u0648\u0644 \u0648\u0627\u0633\u0645 \u0627\u0644\u0639\u0627\u0626\u0644\u0629",
            "email_address": "\u0639\u0646\u0648\u0627\u0646 \u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a",
            "logout": "\u062a\u0633\u062c\u064a\u0644 \u062e\u0631\u0648\u062c",
            "andra": {
                "account": "\u062d\u0633\u0627\u0628 \u0623\u0646\u062f\u0631\u0627",
                "device": "\u062c\u0647\u0627\u0632",
                "device_name": "\u0627\u0633\u0645 \u0627\u0644\u062c\u0647\u0627\u0632"
            },
            "actions": {"logout": "\u0644\u0642\u062f \u0642\u0645\u062a \u0628\u062a\u0633\u062c\u064a\u0644 \u0627\u0644\u062e\u0631\u0648\u062c \u0645\u0646 \u0627\u0644\u062a\u0637\u0628\u064a\u0642 \u0628\u0646\u062c\u0627\u062d"}
        },
        "devices": {
            "connected_devices": "\u0627\u0644\u0623\u062c\u0647\u0632\u0629 \u0627\u0644\u0645\u062a\u0635\u0644\u0629",
            "device_name": "\u0627\u0633\u0645 \u0627\u0644\u062c\u0647\u0627\u0632",
            "device_id": "\u0628\u0637\u0627\u0642\u0629 \u062a\u0639\u0631\u064a\u0641",
            "options": "\u062e\u064a\u0627\u0631\u0627\u062a",
            "change_name": "\u0625\u0639\u0627\u062f\u0629 \u062a\u0633\u0645\u064a\u0629",
            "actions": {
                "device_removed": "\u062a\u0645 \u062d\u0630\u0641 \u0627\u0644\u062c\u0647\u0627\u0632",
                "device_remove_failed": "\u0641\u0634\u0644 \u0641\u064a \u0625\u0632\u0627\u0644\u0629 \u0627\u0644\u062c\u0647\u0627\u0632",
                "device_saved": "\u062a\u0645 \u062d\u0641\u0638 \u0627\u0644\u062a\u063a\u064a\u064a\u0631\u0627\u062a"
            }
        },
        "app": {
            "settings": "\u0625\u0639\u062f\u0627\u062f\u0627\u062a \u0627\u0644\u062a\u0637\u0628\u064a\u0642",
            "enable_voice": "\u0627\u0633\u062a\u062e\u062f\u0645 \u0627\u0644\u0627\u0633\u062a\u062c\u0627\u0628\u0627\u062a \u0627\u0644\u0635\u0648\u062a\u064a\u0629",
            "disable_voice": "\u0642\u0645 \u0628\u0625\u064a\u0642\u0627\u0641 \u062a\u0634\u063a\u064a\u0644 \u0627\u0644\u0627\u0633\u062a\u062c\u0627\u0628\u0627\u062a \u0627\u0644\u0635\u0648\u062a\u064a\u0629"
        }
    },
    "nyumba": {
        "work_mode": {
            "unknown": "\u0645\u062c\u0647\u0648\u0644",
            "home": "\u0641\u064a \u0627\u0644\u0628\u064a\u062a",
            "away": "\u062e\u0627\u0631\u062c \u0627\u0644\u0645\u0646\u0632\u0644",
            "manual": "\u064a\u062f\u0648\u064a",
            "silence": "\u0647\u0627\u062f\u0626"
        },
        "alarm": {
            "enter_code": "\u0623\u062f\u062e\u0644 \u0631\u0645\u0632 \u0627\u0644\u062a\u0646\u0628\u064a\u0647",
            "state": {
                "active": "\u0646\u0634\u064a\u0637",
                "waked_up": "\u0645\u062a\u062d\u0645\u0633",
                "run": "\u0627\u0646\u0637\u0644\u0642\u062a",
                "sleeping": "\u062c\u0627\u0631\u064a \u0627\u0644\u0646\u0648\u0645...",
                "disabling": "\u062c\u0627\u0631\u064a \u0627\u0644\u062a\u0639\u0637\u064a\u0644...",
                "inactive": "\u063a\u064a\u0631 \u0646\u0634\u0637"
            },
            "actions": {
                "activate": "\u062a\u0645 \u0625\u0631\u0633\u0627\u0644 \u0623\u0645\u0631 \u062a\u0641\u0639\u064a\u0644 \u0627\u0644\u062a\u0646\u0628\u064a\u0647",
                "activation_failed": "\u0641\u0634\u0644 \u0627\u0644\u062a\u0646\u0628\u064a\u0647 \u0641\u064a \u0627\u0644\u062a\u0646\u0634\u064a\u0637",
                "send_command": "\u062a\u0645 \u0625\u0631\u0633\u0627\u0644 \u0623\u0645\u0631 \u0625\u0644\u0649 \u0627\u0644\u062a\u0646\u0628\u064a\u0647",
                "send_command_failed": "\u0641\u0634\u0644 \u0641\u064a \u0625\u0631\u0633\u0627\u0644 \u0627\u0644\u0623\u0645\u0631"
            }
        },
        "actions": {
            "send_command": "\u062a\u0645 \u0625\u0631\u0633\u0627\u0644 \u0627\u0644\u0623\u0645\u0631",
            "data_failed": "\u0641\u0634\u0644 \u062a\u062d\u0645\u064a\u0644 \u0627\u0644\u0628\u064a\u0627\u0646\u0627\u062a",
            "send_failed": "\u0641\u0634\u0644 \u0641\u064a \u0646\u0642\u0644 \u0627\u0644\u0628\u064a\u0627\u0646\u0627\u062a"
        }
    }
}