import React from 'react';
import { connect } from "react-redux";
import { setView, alert } from '../actions';
import '../styles/elements/main-panel.scss';
import {VoiceContainer} from "./Voice";
import TranslationService from "../app/services/TranslationService";
import {LanguageComponent} from "./LanguageComponent";
import axios from "axios";
import {config} from "../app/config";

class MainPanel extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            menu: false,
            notifications: false
        };
    }

    open = (screenName) => {
        this.props.setView(screenName);
        this.setState({menu: false, notifications: false});
    }

    setNotificationAsRead = (id) => {
        let that = this;
        axios.patch(config.apiAddress + '/notifications/set-as-read/' + id)
            .then(function (response) {
                that.props.reloadNotificaions();
            }).catch(function(error) {
            });
    }

    countNotifications = () => {
        return this.props.notifications.new.length + this.props.notifications.unread.length;
    }

    authorizeIMid = () => {
        let that = this;
        axios.post(config.apiAddress + '/app/imid/authorize')
            .then(function (response) {
                that.props.alert(TranslationService.trans(that.props.language, 'menu.imid.message'));
            }).catch(function(error) {
                console.log(error)
            });

        this.setState({menu: false, notifications: false});
    }

    /**
     * @returns {XML}
     */
    render() {
        let notificationsCounter = this.countNotifications();

        return (
            <div id="main-panel" className={"imd-bg-transparent-default"} style={{display: (this.props.email !== null ? 'block' : 'none')}}>
                <div className="fake-background" onClick={() => this.setState({menu: false, notifications: false})} style={{display: (this.state.menu || this.state.notifications ? 'block' : 'none')}}></div>
                <VoiceContainer />
                <button
                    type="button"
                    className={'imd-nav-button' + (this.state.menu ? ' opened' : '')}
                    onClick={() => this.setState({menu: !this.state.menu, notifications: false})}
                >
                    <span className="imd-bar"></span>
                    <span className="imd-bar"></span>
                    <span className="imd-bar"></span>
                </button>

                <button
                    type="button"
                    className={"notification-button"}
                    onClick={() => this.setState({menu: false, notifications: !this.state.notifications})}
                >
                    <span className={"imd-icon imd-icon-bell imd-text-contrast"} />
                    <span className={"counter"} style={{display: notificationsCounter === 0 ? 'none' : 'inline-block'}}>
                        {notificationsCounter > 9 ? '9+' : notificationsCounter}
                    </span>
                </button>

                <div className={this.state.menu ? 'imd-bg-transparent-black menu opened' : 'imd-bg-transparent-black menu'}>
                    <ul>
                        <li className={'imd-text-contrast' + (this.props.view === 'main' ? ' active' : '')} onClick={() => this.open('main')}>
                            <span className='imd-icon imd-icon-home' />
                            {TranslationService.trans(this.props.language, 'menu.home')}
                        </li>
                        <li className={'imd-text-contrast' + (this.props.view === 'note' ? ' active' : '')} onClick={() => this.open('note')}>
                            <span className='imd-icon imd-icon-pencil' />
                            {TranslationService.trans(this.props.language, 'menu.note')}
                        </li>
                        <li className={'imd-text-contrast' + (this.props.view === 'calendars' ? ' active' : '')} onClick={() => this.open('calendars')}>
                            <span className='imd-icon imd-icon-calendar' />
                            {TranslationService.trans(this.props.language, 'menu.calendars')}
                        </li>
                        <li className={'imd-text-contrast' + (this.props.view === 'auto' ? ' active' : '')} onClick={() => this.open('auto')} style={{display: (config.env === 'prod' ? 'none' : 'block')}}>
                            <span className='imd-icon imd-icon-road' />
                            Tryb samochodowy
                        </li>
                        {
                            this.props.services.indexOf('nyumba') >= 0 ?
                                <li className={'imd-text-contrast' + (this.props.view === 'nyumba' ? ' active' : '')} onClick={() => this.open('nyumba')}>
                                    <span className='imd-icon imd-icon-nyumba' style={{fontSize: '11pt'}}/>
                                    Nyumba
                                </li> :
                                ''
                        }
                        {
                            this.props.user !== null && this.props.user.subscription !== 'standard' ?
                                <li className={'imd-text-contrast' + (this.props.view === 'finance' ? ' active' : '')} onClick={() => this.open('finance')}>
                                    <span className='imd-icon imd-icon-credit-card' style={{fontSize: '11pt'}}/>
                                    {TranslationService.trans(this.props.language, 'menu.finance')}
                                </li> :
                                ''
                        }
                        {
                            this.props.user !== null && this.props.user.subscription !== 'standard' ?
                                <li className={'imd-text-contrast' + (this.props.view === 'integrations' ? ' active' : '')} onClick={() => this.open('integrations')}>
                                    <span className='imd-icon imd-icon-share2' style={{fontSize: '11pt'}}/>
                                    {TranslationService.trans(this.props.language, 'menu.integrations')}
                                </li> :
                                ''
                        }
                        <li className={'imd-text-contrast' + (this.props.view === 'settings' ? ' active' : '')} onClick={() => this.open('settings')}>
                            <span className='imd-icon imd-icon-cog' />
                            {TranslationService.trans(this.props.language, 'menu.settings')}
                        </li>
                    </ul>

                    <hr style={{margin: '13px 0'}} />

                    <div style={{display: 'flex', margin: '13px'}}>
                        <div className="imid" style={{display: 'inline-block'}}>
                            <div className={"flag imd-bg-gray imd-text-50-gray"} style={{cursor: 'pointer', textAlign: 'center', fontSize: '25px'}}>
                                <img
                                    src={"images/imid-white.png"}
                                    alt={TranslationService.trans(this.props.language, 'menu.imid.authorization')}
                                    onClick={() => this.authorizeIMid()}
                                />
                            </div>
                        </div>
                        <LanguageComponent />
                    </div>
                </div>

                <div className={this.state.notifications ? 'imd-bg-transparent-black menu notifications opened' : 'imd-bg-transparent-black menu notifications'}>
                    <div>
                        {
                            notificationsCounter === 0 ?
                                <span className={"imd-text-accent"}>{TranslationService.trans(this.props.language, 'menu.notifications.empty')}</span> :
                                null
                        }

                        {
                            Object.keys(this.props.notifications.new).map(x => {
                                return (
                                    <div className="imd-notification imd-bg-80-main imd-text-gray" key={"nu" + x}>
                                        <button
                                            type="button"
                                            className="imd-notification-close imd-text-default"
                                            onClick={() => this.setNotificationAsRead(this.props.notifications.new[x].id)}
                                        >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        {this.props.notifications.new[x].message}
                                    </div>
                                )
                            })
                        }

                        {
                            Object.keys(this.props.notifications.unread).map(x => {
                                return (
                                    <div className="imd-notification imd-bg-80-silver imd-text-gray" key={"nu" + x}>
                                        <button
                                            type="button"
                                            className="imd-notification-close imd-text-default"
                                            onClick={() => this.setNotificationAsRead(this.props.notifications.unread[x].id)}
                                        >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        {this.props.notifications.unread[x].message}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user),
        email: (state.user === null ? null : state.user.email),
        view: state.view,
        services: (state.user === null ? [] : state.user.imid)
    }
};

export const MainPanelContainer = connect(mapStateToProps, { setView, alert })(MainPanel);
