import { ar } from "../../translations/ar";
import { de_de } from "../../translations/de-de";
import { en } from "../../translations/en";
import { et_ee } from "../../translations/et-ee";
import { jp_jp } from "../../translations/jp-jp";
import { kk_kz } from "../../translations/kk-kz";
import { pl_pl } from "../../translations/pl-pl";
import { uk_ua } from "../../translations/uk-ua";


const translations = {
    "ar": ar,
    "de-de": de_de,
    "en": en,
    "en-us": en,
    "en-gb": en,
    "et-ee": et_ee,
    "jp-jp": jp_jp,
    "kk-kz": kk_kz,
    "pl-pl": pl_pl,
    "uk-ua": uk_ua,
}

export default class TranslationService {
    static getLanguage(user) {
        let language = navigator.language.toLowerCase().replace('_', '-');

        if (translations[language] !== undefined) {
            language = this.getDefaultLanguage();
        }

        if (user !== null && translations[user.language] !== undefined) {
            language = user.language;
        }

        let settings = localStorage.getItem('language');
        if (settings !== null && translations[settings] !== undefined) {
            language = settings;
        }

        return language;
    }

    static getTextDirection(user) {
        return translations[this.getLanguage(user)]._direction;
    }

    static getDefaultLanguage() {
        return 'pl-pl';
    }

    static getMonthsList(lang)
    {
        let months = this.trans(lang, '_months');
        if (months !== '_months') {
            return months;
        }

        return [
            {id: 1, name: '01'},
            {id: 2, name: '02'},
            {id: 3, name: '03'},
            {id: 4, name: '04'},
            {id: 5, name: '05'},
            {id: 6, name: '06'},
            {id: 7, name: '07'},
            {id: 8, name: '08'},
            {id: 9, name: '09'},
            {id: 10, name: '10'},
            {id: 11, name: '11'},
            {id: 12, name: '12'}
        ];
    }

    static trans(lang, key, params = {}) {
        if (translations[lang] === undefined) {
            lang = this.getDefaultLanguage();
        }

        let flattenTranslation = this.flatObject(translations[lang]);
        if (key[0] === '_') {
            flattenTranslation = translations[lang];
        }

        if (flattenTranslation[key] === undefined) {
            return key;
        }


        let text = flattenTranslation[key];

        if (params !== undefined && params !== null && typeof params === 'object') {
            Object.keys(params).forEach(key => {
                text = text.replaceAll(key, params[key]);
            });
        }

        return text;
    }

    static flatObject(object, prefix = "") {
        let result = {};

        Object.keys(object).forEach((key) => {
            if (typeof object[key] === 'object') {
                let children= this.flatObject(object[key], key + '.');

                Object.keys(children).forEach((childrenKey) => {
                    result[prefix + childrenKey] = children[childrenKey];
                });
            } else {
                result[prefix + key] = object[key];
            }
        });

        return result;
    }

    static getLanguageName(language) {
        if (translations[language] === undefined) {
            return language;
        }

        return translations[language]['_name'];
    }

    static getLanguageAvatar(language) {
        return this.getLanguageName(language)[0];
    }
}
