import React from 'react';
import ScreenComponent from './ScreenComponent';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from '../../actions';
import '../../styles/elements/screen.scss';
import '../../styles/screens/integrations.scss';
import TranslationService from "../../app/services/TranslationService";
import { WebhooksTab } from "../integrations/WebhooksTab";


class IntegrationsClass extends ScreenComponent
{
    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        this.state = {
            tab: 'webhooks'
        };
    };

    /**
     * @returns {string}
     */
    getScreenName() {
        return 'integrations';
    };

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.view !== this.getScreenName() && this.props.view === this.getScreenName()) {
            // this.loadNotes();
        }
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className="screen" style={this.getStyle()} id="screen-integrations">
                <h1>{TranslationService.trans(this.props.language, 'menu.integrations')}</h1>

                <div className="tabs tabs-top">
                    <ul className="tabs-navigation">
                        <li className={(this.state.tab === 'webhooks' ? 'active' : null)}>
                            <a href="#" className="imd-border-color-main imd-text-main" onClick={() => this.setState({tab: 'webhooks'})}>
                                {TranslationService.trans(this.props.language, 'integrations.webhooks.title')}
                            </a>
                        </li>
                    </ul>

                    <WebhooksTab
                        active={true}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        view: state.view,
        language: TranslationService.getLanguage(state.user)
    }
};

export const IntegrationsScreen = connect(mapStateToProps, {preloaderShow, preloaderHide, alert})(IntegrationsClass);
