export const et_ee = {
    "_name": "Eesti",
    "_direction": "ltr",
    "_welcome": "Tere, %message",
    "common": {
        "save": "Salvesta",
        "cancel": "T\u00fchista",
        "delete": "Kustuta",
        "ok": "Okei",
        "actions": {"save_failed": "Muudatusi ei saa salvestada"}
    },
    "login": {
        "imid_button": "Logige sisse IMidiga",
        "license": "Litsents",
        "actions": {"failed": "Sisselogimine eba\u00f5nnestus", "relogin_required": "Vajalik sisselogimine"}
    },
    "main": {
        "how_can_i_help_you": "Kuidas ma teid aidata saan?",
        "image_answer": "Graafiline vastus",
        "actions": {"query_failed": "Kahjuks ei leia ma vastust."}
    },
    "menu": {
        "home": "Kodu",
        "settings": "Seaded"
    },
    "settings": {
        "tabs": {
            "account": "Konto",
            "devices": "Seadmed",
            "app": "Rakendus",
            "license": "Litsents"
        },
        "account": {
            "full_name": "T\u00e4isnimi",
            "email_address": "E-posti aadress",
            "logout": "Logi v\u00e4lja",
            "andra": {"account": "Andra konto", "device": "Seade", "device_name": "Seadme nimi"},
            "actions": {"logout": "Edukas v\u00e4ljalogimine"}
        },
        "devices": {
            "connected_devices": "\u00dchendatud seadmed",
            "device_name": "Seadme nimi",
            "device_id": "Id",
            "options": "Valikud",
            "change_name": "Muuda nime",
            "actions": {
                "device_removed": "Seade eemaldati",
                "device_remove_failed": "Seadme eemaldamine eba\u00f5nnestus",
                "device_saved": "Salvestatud muudatused"
            }
        },
        "app": {
            "settings": "Rakenduse seaded",
            "enable_voice": "Kasutage h\u00e4\u00e4lt",
            "disable_voice": "Keela h\u00e4\u00e4l"
        }
    },
    "nyumba": {
        "work_mode": {
            "unknown": "TUNDMATU",
            "home": "Kodus",
            "away": "Eemal",
            "manual": "K\u00e4siraamat",
            "silence": "Vaikus"
        },
        "alarm": {
            "enter_code": "Sisestage h\u00e4irekood",
            "state": {
                "active": "Aktiivne",
                "waked_up": "\u00c4rkas \u00fcles",
                "run": "Jookse",
                "sleeping": "Magab...",
                "disabling": "Deaktiveerimine...",
                "inactive": "Mitteaktiivne"
            },
            "actions": {
                "activate": "H\u00e4ire aktiveerimise k\u00e4sk saadeti",
                "activation_failed": "S\u00fcsteem ei saa h\u00e4iret aktiveerida",
                "send_command": "K\u00e4sk saadeti h\u00e4irele",
                "send_command_failed": "K\u00e4su saatmine eba\u00f5nnestus"
            }
        },
        "actions": {
            "send_command": "K\u00e4sk saadeti",
            "data_failed": "Andmeid ei saa laadida",
            "send_failed": "Andmeid ei saa saata"
        }
    }
}
