export const config = {
    env: 'develop',
    apiAddress: 'https://assistant.icymat.pl',
    apiVersion: '2.0.5',
    theme: 'dark',
    imidServer: 'https://id.icymat.pl/',
    imidOAuthId: '2c5ed8c8c25912a3bb6f901876afaaf5269666fe.id.icymat.pl',
    icymat_assistant: false,
    special_colors: null
};
