import React from 'react';
import { connect } from 'react-redux';
import '../../styles/screens/nyumba.scss';
import { say, alert, preloaderShow, preloaderHide } from "../../actions";
import axios from "axios";
import { config } from "../../app/config";
import ScreenComponent from "./ScreenComponent";
import { AlarmButton } from "./nyumba/AlarmButton";
import TranslationService from "../../app/services/TranslationService";
import {DevicesList} from "./nyumba/DevicesList";
import {LicenseComponent} from "./settings/LicenseComponent";
import {Confirmation} from "../Confirmation";

const allowedActions = {
    '4': {
        'play_radio': 'imd-icon imd-icon-play2',
        'play_stop': 'imd-icon imd-icon-stop'
    },
    '12': {
        'on': 'imd-icon imd-icon-radio-checked2',
        'off': 'imd-icon imd-icon-radio-unchecked'
    },
    '25': {
        'off': 'imd-icon imd-icon-radio-unchecked'
    },
    '27': {
        'play_radio': 'imd-icon imd-icon-play2',
        'play_stop': 'imd-icon imd-icon-stop'
    },
    '29': {
        'off': 'imd-icon imd-icon-radio-unchecked'
    }
};

export class NyumbaScreen extends ScreenComponent {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            master: {
                name: 'ładowanie...',
                work_mode: 'manual',
                alarm: null
            },
            tab: 'scenes',
            scenes: {},
            devices: {}
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.view !== this.getScreenName() && this.props.view === this.getScreenName()) {
            this.loadMasters();
        }
    };

    getScreenName = () => {
        return 'nyumba';
    }

    loadMasters = () => {
        this.props.preloaderShow();

        let that = this;
        axios.get(config.apiAddress + '/app/nyumba/masters')
            .then(function (response) {
                let ids = Object.keys(response.data);
                that.setState({master: response.data[ids[0]]});

                that.loadScenes();
            }).catch(function(response) {
                that.props.preloaderHide();
                that.setState({master: {name: TranslationService.trans(that.props.language, 'nyumba.actions.data_failed')}});
            });
    }

    loadScenes = () => {
        this.props.preloaderShow();

        let that = this;
        axios.get(config.apiAddress + '/app/nyumba/master/' + this.state.master.id + '/scenes')
            .then(function (response) {
                that.setState({scenes: response.data});

                that.loadDevices()
            }).catch(function(response) {
                that.props.preloaderHide();
                that.setState({master: {name: TranslationService.trans(that.props.language, 'nyumba.actions.data_failed')}});
            });
    }

    loadDevices = () => {
        this.props.preloaderShow();

        let that = this;
        axios.get(config.apiAddress + '/app/nyumba/master/' + this.state.master.id + '/devices')
            .then(function (response) {
                that.setState({devices: response.data});
                that.props.preloaderHide();
            }).catch(function(response) {
                that.props.preloaderHide();
                that.setState({master: {name: TranslationService.trans(that.props.language, 'nyumba.actions.data_failed')}});
            });
    }

    sendAction = (command) => {
        let that = this;
        axios.post(config.apiAddress + '/app/nyumba/master/' + this.state.master.id + '/instructions', {instructions: [command]})
            .then(function (response) {
                that.props.preloaderHide();
                that.props.alert(TranslationService.trans(that.props.language, 'nyumba.actions.send_command'));
            }).catch(function(response) {
                that.props.preloaderHide();
                that.props.alert(TranslationService.trans(that.props.language, 'nyumba.actions.send_failed'));
            });
    }

    renderSummary = () => {
        let renderButton = function(that) {
            if (that.state.master.work_mode === 'home') {
                return (<button className={"imd-button imd-button-sm imd-border-color-green imd-text-green"} onClick={() => that.sendAction('workmode away')}>
                    {TranslationService.trans(that.props.language, 'nyumba.work_mode.home')}
                </button>);
            } else if (that.state.master.work_mode === 'away') {
                return (<button className={"imd-button imd-button-sm imd-border-color-yellow imd-text-yellow"} onClick={() => that.sendAction('workmode home')}>
                    {TranslationService.trans(that.props.language, 'nyumba.work_mode.away')}
                </button>);
            } else if (that.state.master.work_mode === 'manual') {
                return (<button className={"imd-button imd-button-sm imd-border-color-blue imd-text-blue"} onClick={() => that.sendAction('workmode home')}>
                    {TranslationService.trans(that.props.language, 'nyumba.work_mode.manual')}
                </button>);
            } else if (that.state.master.work_mode === 'silence') {
                return (<button className={"imd-button imd-button-sm imd-border-color-cyan imd-text-cyan"} onClick={() => that.sendAction('workmode home')}>
                    {TranslationService.trans(that.props.language, 'nyumba.work_mode.silence')}
                </button>);
            } else {
                return (<button className={"imd-button imd-button-sm imd-border-color-gray imd-text-gray"}>
                    {TranslationService.trans(that.props.language, 'nyumba.work_mode.unknown')}
                </button>);
            }
        };

        return (
            <div>
                {renderButton(this)}
                {
                    this.state.master.alarm && this.state.master.alarm !== null ?
                        <AlarmButton
                            status={this.state.master.alarm.status}
                            mode={this.state.master.alarm.mode}
                            masterId={this.state.master.id}
                            callback={this.loadMasters}
                        /> :
                        null
                }
            </div>
        );
    }

    renderScenes = () => {
        return (
            <div style={{maxHeight: '40vh', overflowY: 'auto'}} className={'imd-row'}>
                {
                    Object.keys(this.state.scenes).map(id => {
                        let scene = this.state.scenes[id];

                        return (<div key={id} className={'imd-col-xs-12 imd-col-md-6 imd-col-lg-3'}>
                            <div className={'imd-shadow-dark imd-text-white'} style={{margin: '7px', padding: '13px'}}>
                                {scene.name}
                                <button
                                    style={{fontSize: '2rem', border: 'none', background: 'none', float: 'right', padding: 0, marginTop: '-9px', cursor: 'pointer'}}
                                    onClick={() => this.sendAction('SCENE: ' + scene.id)}
                                >
                                    <span className="imd-icon imd-icon-play3 imd-text-teal"></span>
                                </button>
                            </div>
                        </div>)
                    })
                }
            </div>
        );
    }

    render() {
        return (
            <div className="screen" style={this.getStyle()} id="screen-nyumba">
                <h1 className={"device-name"}>Nyumba: {this.state.master.name}</h1>

                <div style={{padding: '26px 0'}}>
                    {this.renderSummary()}
                </div>

                <div className="tabs tabs-top">
                    <ul className="tabs-navigation">
                        <li className={(this.state.tab === 'scenes' ? 'active' : null)}>
                            <a href="#" className="imd-border-color-main imd-text-main" onClick={() => this.setState({tab: 'scenes'})}>
                                {TranslationService.trans(this.props.language, 'nyumba.headers.scenes')}
                            </a>
                        </li>
                        <li className={(this.state.tab === 'devices' ? 'active' : null)}>
                            <a href="#" className="imd-border-color-main imd-text-main" onClick={() => this.setState({tab: 'devices'})}>
                                {TranslationService.trans(this.props.language, 'nyumba.headers.devices')}
                            </a>
                        </li>
                    </ul>

                    <div className={(this.state.tab === 'scenes' ? 'tab imd-border-color-20-silver imd-text-gray active' : 'tab imd-border-color-20-silver imd-text-gray')}>
                        <div className={"container"} style={{height: 'calc(100vh - 270px)'}}>
                            {this.renderScenes()}
                        </div>
                    </div>

                    <div className={(this.state.tab === 'devices' ? 'tab imd-border-color-20-silver imd-text-gray active' : 'tab imd-border-color-20-silver imd-text-gray')}>
                        <div className={"container"} style={{height: 'calc(100vh - 270px)'}}>
                            <DevicesList devices={this.state.devices} sendAction={this.sendAction} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user),
        view: state.view
    }
};
const mapDispatchToProps = {
    say,
    alert,
    preloaderShow,
    preloaderHide
};

export const NyumbaScreenContainer = connect(mapStateToProps, mapDispatchToProps)(NyumbaScreen);
